import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as dayjs from 'dayjs'
import '@/assets/global.css';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2'

function isTokenExpired(token) {
  if (!token) return true;
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000;
}

function startSessionTimer() {
  const token = localStorage.getItem('token');
  if (token && !isTokenExpired(token)) {
    const { exp } = jwtDecode(token);
    const now = Date.now();
    const expTime = exp * 1000; 

   
    const alerts = [
      { time: expTime - 600000, message: 'Segera Login Ulang Sesi Anda akan berakhir dalam 10 menit!' }, // 10 menit
      { time: expTime - 300000, message: 'Segera Login Ulang Sesi Anda akan berakhir dalam 5 menit!' },  // 5 menit
      { time: expTime - 60000, message: 'Anda akan Logout Otomatis dalam 1 menit!' }   // 1 menit
    ];

    
    alerts.forEach(alert => {
      if (alert.time > now) { 
        const delay = alert.time - now;

        console.log(`Peringatan dijadwalkan dalam ${delay / 1000} detik dengan pesan: ${alert.message}`);

        setTimeout(() => {
          Swal.fire({
            title: 'Peringatan!',
            text: alert.message,
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Ok',
            timer: 60000, 
            timerProgressBar: true
          }).then(async () => {
         
            if (alert.message.includes('1 menit')) {
              const token = localStorage.getItem('token');
              const id = token ? token.split("/")[1] : null;
              if (id) {
                await axios.post('/user/logout', { userId: id });
              }
              localStorage.removeItem('token');
              localStorage.removeItem('tokenLaravel');
              localStorage.removeItem('tokenSatuSehat');
              location.reload(); 
            }
          });
        }, delay);
      }
    });

    
    const checkTokenExpired = setInterval(() => {
      if (isTokenExpired(token)) {
        clearInterval(checkTokenExpired);
        localStorage.removeItem('token');
        localStorage.removeItem('tokenLaravel');
        localStorage.removeItem('tokenSatuSehat');
        location.reload(); 
      }
    }, 1000);
  }
}


axios.interceptors.request.use((config) => {
	// config.baseURL = 'http://localhost:3001'
	config.baseURL = 'https://devnode.solumedis.com'
	// config.baseURL = 'https://nodeapi.solumedis.com'
  const token = localStorage.getItem('token');
  const tokenSatuSehat = localStorage.getItem('tokenSatuSehat');
  const tokenObat = localStorage.getItem('token-obat');

  if (isTokenExpired(token)) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenLaravel');
    localStorage.removeItem('tokenSatuSehat');
  }

  config.withCredentials = true;
  config.headers = {
    'Authorization': 'Bearer ' + token,
    'Authorization-Satu-Sehat': 'Bearer ' + tokenSatuSehat,
    'Authorization-Token-Obat': 'Bearer ' + tokenObat,
  };
  return config;
});



export const laravel = axios.create({
    //  baseURL: 'http://localhost:8000',
    baseURL: 'https://devbackend.solumedis.com',
    // baseURL: 'https://api.solumedis.com',
	withCredentials: true,
  withXSRFToken: true
})

laravel.interceptors.request.use((config) => {
    const token = localStorage.getItem('tokenLaravel');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const scoket_io = axios.create({
     baseURL: 'https://22m8b9kg-3004.asse.devtunnels.ms',
	// baseURL: 'http://localhost:3004',
	withCredentials: true,
    headers : {
		'Authorization': 'Bearer ' + localStorage.getItem('token')
	}
})

export const urlSocket = {
	// 'baseUrl': 'http://localhost:3004',
	'baseUrl': 'https://22m8b9kg-3004.asse.devtunnels.ms'
}
export const urlGambar = {
	// 'baseUrl': 'http://localhost:3001',
	'baseUrl': 'https://22m8b9kg-3004.asse.devtunnels.ms'
}




store.dispatch('auth/me').then(() => {
	createApp(App).use(store).use(router).use(ElementPlus).use(dayjs).mount('#app');startSessionTimer();
})
